import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
} from '@mui/material';

import logo from "../Media/Images/lupe-reyes.png"
import { TransitionProps } from '@mui/material/transitions';
import "../Styles/Pages.scss"
import CloseIcon from '@mui/icons-material/Close';
import logoBarlleno from "../Media/Images/logoBarlleno.svg"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsInterface {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    data: any
}

const ListCoupon: React.FC<PropsInterface> = (props: PropsInterface) => {
    const [typeLine, setTypeLine] = useState('Fila');
    const [filteredData, setFilteredData] = React.useState<any[]>([]);

    const close = () => {
        props.setOpen(false);
        setTypeLine('Fila')
    }

    useEffect(() => {
        if (props.data) {
            if (typeLine === 'Diagonal') {
                const diagonalData = props.data.filter((reward: any) => reward.key === 'diagonal');
                setFilteredData(diagonalData);
            } else if (typeLine === 'Columna') {
                const columnData = props.data.filter((reward: any) => reward.key === 'horizontal');
                setFilteredData(columnData);
            } else {
                const verticalData = props.data.filter((reward: any) => reward.key === 'vertical');
                setFilteredData(verticalData);
            }
        }
    }, [props.data, typeLine]);

    const setType = (type: string) => {
        setTypeLine(type);

        if (type === 'Diagonal') {
            const diagonalData = props.data.filter((reward: any) => reward.key === 'diagonal');
            setFilteredData(diagonalData);
        } else if (type === 'Columna') {
            const columnData = props.data.filter((reward: any) => reward.key === 'horizontal');
            setFilteredData(columnData);
        } else {
            const verticalData = props.data.filter((reward: any) => reward.key === 'vertical');
            setFilteredData(verticalData);
        }
    }
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={props.open}
        TransitionComponent={Transition}
        onClose={close}
        className="modal">
        <DialogTitle
          style={{
            backgroundColor: "#B3261E",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}>
          <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
            <img
              style={{ width: 60, height: 30, marginLeft: 0 }}
              src={logo}></img>
            <p style={{ margin: 0, fontSize: "medium", textAlign: "center" }}>
              LISTA DE PREMIOS
            </p>
          </div>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              backgroundColor: "#E77E2C",
              color: "white",
              width: 35,
              height: 35,
            }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#EAEAEA" }}>
          <div style={{ display: "flex", gap: 10, marginTop: 15 }}>
            <div
              onClick={() => {
                setType("Fila");
              }}
              style={{
                backgroundColor:
                  typeLine === "Fila" ? "#B3261E" : "transparent",
                color: typeLine === "Fila" ? "white" : "#B3261E",
                border: "1px solid #B3261E",
                paddingInline: 10,
                borderRadius: 15,
                paddingBlock: 2,
                fontWeight: "bold",
                fontSize: "small",
              }}>
              Filas
            </div>
            <div
              onClick={() => {
                setType("Columna");
              }}
              style={{
                backgroundColor:
                  typeLine === "Columna" ? "#B3261E" : "transparent",
                color: typeLine === "Columna" ? "white" : "#B3261E",
                border: "1px solid #B3261E",
                paddingInline: 10,
                borderRadius: 15,
                paddingBlock: 2,
                fontWeight: "bold",
                fontSize: "small",
              }}>
              Columnas
            </div>
            <div
              onClick={() => {
                setType("Diagonal");
              }}
              style={{
                backgroundColor:
                  typeLine === "Diagonal" ? "#B3261E" : "transparent",
                color: typeLine === "Diagonal" ? "white" : "#B3261E",
                border: "1px solid #B3261E",
                paddingInline: 10,
                borderRadius: 15,
                paddingBlock: 2,
                fontWeight: "bold",
                fontSize: "small",
              }}>
              Diagonal
            </div>
          </div>
          {props?.data?.length > 0 ? (
            filteredData.map((item: any, key: number) => {
              return (
                <div key={key}>
                  <div
                    style={{
                      marginTop: 20,
                      fontWeight: "bold",
                      fontSize: "small",
                      color: "#59322D",
                    }}>
                    {typeLine + " " + Number(key + 1)}
                  </div>
                  <div
                    style={{
                      borderRadius: 15,
                      display: "flex",
                      background:
                        "linear-gradient(90deg, rgba(255,186,34,1) 0%, rgba(251,92,28,1) 100%)",
                      marginTop: 5,
                    }}>
                    <div
                      style={{
                        backgroundColor: "#FFE7D4",
                        display: "flex",
                        margin: 4,
                        borderRadius: 15,
                        width: "100%",
                      }}>
                      <div className="discountSidePrize">
                        <img
                          alt="Producto"
                          src={item.image ? item.image : ""}
                          style={{ width: "100%", objectFit: "contain" }}></img>
                      </div>
                      {/* <div className='discountSidePrize'>
                            <div className='specialFont'>{item.coupon.split(' ')[0]}</div>
                            <div style={{fontSize: 'small', fontWeight: 'bold'}}>{item.coupon.split(' ').slice(1).join(' ')}</div>
                        </div> */}
                      <div className="informationCouponSide">
                        <div
                          style={{
                            width: "calc(100% - 10px)",
                            height: 20,
                            textAlign: "right",
                          }}>
                          <img
                            alt="Logo"
                            style={{ width: 20, height: "100%" }}
                            src={logoBarlleno}></img>
                        </div>
                        <div>
                          {/* <div>{item.key + "-" + item.key2}</div> */}
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "small",
                              marginTop: 5,
                              marginBottom: 10,
                              color: "#59322D",
                              width: "80%",
                              marginInline: "auto",
                              maxWidth: 200,
                            }}>
                            {item.description}
                          </div>
                          {/* <Button 
                                        disabled={!item.applied}
                                        onClick={()=>{}} 
                                        style={{ 
                                            backgroundColor: item.applied ? "#E84137" : "#f39486", 
                                            color:"white", 
                                            fontWeight:"bold", 
                                            borderRadius: 20,
                                            width: '80%',
                                            maxWidth: 200
                                        }}
                                    >
                                        CANJEAR
                                </Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div></div>
          )}
        </DialogContent>
      </Dialog>
    );
}

export default ListCoupon; 