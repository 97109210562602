import NotFoundImage from "../Media/Images/404.png";
import "../Styles/404.scss";

const NotFound: React.FC = () => {
    return(
        <div style={{ backgroundColor:"white", height:"100vh"}}>
            <div className="not-found-page">
                <div style={{ height:"20%" }}></div>
                <img src={NotFoundImage} alt="Not Found page"/>
            </div>
        </div>
    )
}

export default NotFound