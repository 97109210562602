import React from "react";

export interface UserInterface {
    name:string
    lastName:string
    countryCode:string
    token:string
    email:string
}

export interface Context{
    user: UserInterface
    data:any,
    assignateUser: (userData:UserInterface)=> void
    assignateData: (data:any)=> void
}

const ContextP = React.createContext<Context>({
    user:{
        name:"",
        lastName:"",
        token:"",
        countryCode:"",
        email:""
    },
    data: {},
    assignateUser: ()=>{},
    assignateData: ()=>{}
})

export default ContextP;