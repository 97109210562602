import React, { Dispatch, SetStateAction } from 'react';
import {
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import "../Styles/Pages.scss"
import logo from "../Media/Images/lupe-reyes.png"
import CloseIcon from '@mui/icons-material/Close';
import crown from "../Media/Images/crown.png"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsInterface {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    data: any
}

const DetailOrder: React.FC<PropsInterface> = (props: PropsInterface) => {
    const close = () => {
        props.setOpen(false)
    }
    return (
      <Dialog
        fullWidth
        open={props.open}
        TransitionComponent={Transition}
        onClose={close}
        className="modal">
        <DialogTitle
          style={{
            backgroundColor: "#B3261E",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontWeight: "bold",
          }}>
          <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
            <img
              style={{ width: 60, height: 30, marginLeft: 0 }}
              src={logo}></img>
            <p style={{ margin: 0, fontSize: "medium" }}>
              {props?.data?.number}
            </p>
          </div>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              backgroundColor: "#E77E2C",
              color: "white",
              width: 35,
              height: 35,
            }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ backgroundColor: "#EAEAEA", color: "#112A56", paddingTop: 15 }}>
          <div style={{ display: "flex", gap: 5 }}>
            <div>
              <p style={{ margin: 0 }}>
                <img style={{ height: 10 }} src={crown} alt="coronita"></img>
              </p>
            </div>
            <div>
              {props.data && props.data.type === "section" ? (
                <div>
                  <p
                    style={{
                      fontSize: "small",
                      margin: 0,
                      marginTop: 5,
                    }}>
                    Compra productos de la categoría{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {props.data.section.name ? props.data.section.name : ""}
                    </span>{" "}
                    para rellenar esta casilla
                  </p>
                </div>
              ) : props.data && props.data.type === "nothing" ? (
                <p style={{ fontSize: "small", margin: 0, marginTop: 5 }}>
                  ¡Este día, en barlleno te invitamos a disfrutar de la libertad de elegir y adquirir la bebida que más te apetezca!
                </p>
              ) : (
                <p style={{ fontSize: "small", margin: 0, marginTop: 5 }}>
                  Cuando compres un producto dentro de la categoría Lupe Reyes
                  llenarás una casilla
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 20,
              marginTop: 15,
              paddingLeft: 15,
              justifyContent: "left",
            }}>
            {props.data &&
              props.data.bricks.map((brick: any, i: number) => {
                return (
                  <div key={i} className="product2">
                    <div
                      style={{
                        border: "1px solid #EAEAEA",
                        borderRadius: 15,
                        height: 100,
                        width: 90,
                      }}>
                      <img
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        src={
                          brick.image
                            ? brick.image.downloadURL
                            : "https://firebasestorage.googleapis.com/v0/b/barlleno.appspot.com/o/public%2FiconBotella2021.png?alt=media&token=3f057adb-1ae2-4fdc-b3ff-a9ef6ab94dd1"
                        }></img>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        textAlign: "left",
                        fontSize: "small",
                      }}>
                      {brick.name}
                    </div>
                  </div>
                );
              })}
          </div>
        </DialogContent>
      </Dialog>
    );
}

export default DetailOrder;