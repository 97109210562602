import { Slide, Grow, Button } from "@mui/material";
import "../Styles/Pages.scss";
import starPage from "../Media/Images/heartFire.svg";
import logo from "../Media/Images/logo.svg"
import { useNavigate, useParams } from "react-router-dom";

const PageFifth: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const navigateTo = (): void => {
        navigate(`/${id}/orders/fifth`);
      };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className="page-fourth">
        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", left: "60%", top: "5%" }}>
            <img src={starPage} alt="hourglass"></img>
          </div>
          <div style={{ position: "absolute", left: "20%", bottom: "5%" }}>
            <img src={starPage} alt="hourglass"></img>
          </div>
          <Grow in={true}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}>
              <div className="messagePageOne">
                <div
                  style={{
                    position: "absolute",
                    right: "-20px",
                    top: "-20px",
                  }}>
                  <img src={starPage} alt="hourglass"></img>
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "-20px",
                    bottom: "-30px",
                  }}>
                  <img src={starPage} alt="hourglass"></img>
                </div>
                <div style={{fontSize: 'larger', fontWeight: 'bold'}}>
                    Comparte otro año más con
                </div>
                <div className="categoryContainer">
                    <img style={{height: '100%', width: '100%', maxWidth: 200}} src={logo}></img>
                </div>
              </div>
            </div>
          </Grow>
        </div>
      </div>
    </Slide>
  );
};
export default PageFifth;
