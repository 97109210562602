import React, { useState } from "react";
import ContextP, { Context, UserInterface } from "./Context"

interface PropsInterface {
    children: React.ReactNode
}

const ContexProvider:React.FC<PropsInterface> = ( props: PropsInterface ) =>{
    const [user, setUser] = useState<UserInterface>({
        name:"",
        lastName:"",
        token:"",
        countryCode:"",
        email:""
    })
    const [data, setData] = useState()

    const assignateUser = ( userData:UserInterface ) =>{
        setUser(userData)
    }

    const assignateData = (data: any) => {
        setData(data)
    }

    const context : Context = {
        user,
        data,
        assignateUser,
        assignateData
    }
    return(
        <ContextP.Provider value={context}>
            { props.children }
        </ContextP.Provider>
    )
}
export default ContexProvider;