import { useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { Button, Grow } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "../Styles/Pages.scss";
import { useState } from 'react';
import ContextP from "../Context/Context";
import hourglass from "../Media/Images/clinking_glasses.svg"

const PageOne: React.FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const context = useContext(ContextP)
    const [loader, setLoader] = useState(true)
    const [error, setError] = useState(false)
    const [user, setUser] =useState({  
        token:"",
        name:"",
        lastName:"",
        countryCode:"",
        email:""
    })
    const [data, setData] =useState<any>()

    // Funcion de carga del componente
    useEffect(()=>{
        if ( context.user.token !== "" ) {
            setUser(context.user)
            setData(context.data)
            setLoader(false)
        } else {
            getData()
        }
        // eslint-disable-next-line
    },[])

    // Obtiene la informacion inicail
    const getData = async ():Promise<void> => {
        let body = {
            token: id
        }
        let countryCode = "";
        // Obtiene la informacion del usuario
        await axios.post(`${ process.env.REACT_APP_URL }/accounts/get/user`, body).then((response)=>{
            let data = response.data.data
            countryCode = response.data.data.country
            setUser({
                token: id !== undefined ? id : "",
                name: data.name ? data.name : "",
                lastName: data.lastname ? data.lastname : "",
                countryCode: data.country ? data.country : "",
                email: data.email ? data.email : ""
            })
        }).catch((error)=>{
            setError(true)
            setLoader(false)
        })

        // En dado caso que falle la obtencion del usuario
        if ( !error ) {
            await axios.post(`${ process.env.REACT_APP_URL }/service/barlleno/orders/generate/report/year`, body).then(async(response)=>{
                const data = await ordersProducts(response.data.data.orders[countryCode])
                if(data.length > 0){
                    await axios.post(`${ process.env.REACT_APP_URL }/service/bricks/get/brickbyID`, {token: id, data: {$key: data[0].key}}).then(async(infoProduct)=>{
                        console.log(infoProduct.data.data.categoryBase[0])
                        await axios.post(`${ process.env.REACT_APP_URL }/service/bricks/get/brickbyID`, {token: id, data: {$key: infoProduct.data.data.category}}).then(async(product:any)=>{
                            response.data.data.information.categoryName = product.data.data.name;
                            await setData({...response.data.data, products:data,infoProduct: infoProduct, infoCategory: product.data.data})
                        })                  
                    })
                }else{
                    await setData({...response.data.data, products:data,infoProduct: {},  infoCategory: {}})
                }
                setLoader(false)
            }).catch((error)=>{
                setError(true)
                setLoader(false)
            })
        }
    }

    const ordersProducts = async (categories: any) => {
        let products: Array<any> = [];    
        categories.forEach((category: any) => {
            products = products.concat(category.producstArray);
        });
    
        products.sort((a, b) => b.quantity - a.quantity);
        return products;
    };

    // Navegacion a la siguiente pagina
    const navigateTo = (): void => {
        if ( context.user.token === "" ) {
            context.assignateUser(user)
            context.assignateData({
                orders: data.orders,
                information: data.information,
                products: data.products,
                infoProduct: data.infoProduct,
                infoCategory: data.infoCategory
            })
        }
        navigate(`two`)
    }
    
    return(
        <div className="page-one">
            {
                loader ? 
                    <>
                        <div className='loader'>
                            <CircularProgress color="inherit" style={{ color:"white" }}/>
                        </div>
                    </>
                :
                    error ?
                    <div className='loader' style={{ textAlign:"center", marginTop:"45%"}}>
                        <h1>Algo salio mal, intene lo mas tarde.</h1>
                    </div>
                    :
                    data.orders[user.countryCode]?.length === 0 ? 
                    <div style={{height: '100%', position: 'relative'}}>
                    <div style={{position: 'absolute', left: '60%', top: '5%'}}><img src={hourglass} alt="hourglass"></img></div>
                    <div style={{position: 'absolute', left: '20%', bottom: '5%'}}><img src={hourglass} alt="hourglass"></img></div>
                    <Grow in={!loader}>
                        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <div className="messagePageOne">
                            <div style={{position: 'absolute', right: '-20px', top: '-20px'}}><img src={hourglass} alt="hourglass"></img></div>
                            <div style={{position: 'absolute', left: '-20px', bottom: '-30px'}}><img src={hourglass} alt="hourglass"></img></div>
                                <p style={{fontWeight: '700', fontSize: 'large', marginBottom: 0}}>¡CHEERS!</p>
                                <p style={{fontWeight: '700', fontSize: 'large', marginTop: 0}}>Por las aventuras que compartimos este año 2023</p>
                                <Button sx={{ marginTop: '10px',fontWeight: 'bold',background: "#112A56", color: 'white', borderRadius: 2, paddingInline: 2}} aria-label="delete" onClick={navigateTo}>
                                    <p style={{margin: 0, textTransform: 'none', lineHeight: 'normal'}}>Hemos descubierto que eres...</p>
                                </Button>
                            </div>
                        </div>
                    </Grow>
                </div>
                        :
                            <div style={{height: '100%', position: 'relative'}}>
                                <div style={{position: 'absolute', left: '60%', top: '5%'}}><img src={hourglass} alt="hourglass"></img></div>
                                <div style={{position: 'absolute', left: '20%', bottom: '5%'}}><img src={hourglass} alt="hourglass"></img></div>
                                <Grow in={!loader}>
                                    <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                        <div className="messagePageOne">
                                        <div style={{position: 'absolute', right: '-20px', top: '-20px'}}><img src={hourglass} alt="hourglass"></img></div>
                                        <div style={{position: 'absolute', left: '-20px', bottom: '-30px'}}><img src={hourglass} alt="hourglass"></img></div>
                                            <p style={{fontWeight: '700', fontSize: 'large', marginBottom: 0}}>¡CHEERS!</p>
                                            <p style={{fontWeight: '700', fontSize: 'large', marginTop: 0}}>Por las aventuras que compartimos este año 2023</p>
                                            <Button sx={{ marginTop: '10px',fontWeight: 'bold',background: "#112A56", color: 'white', borderRadius: 2, paddingInline: 2}} aria-label="delete" onClick={navigateTo}>
                                                <p style={{margin: 0, textTransform: 'none', lineHeight: 'normal'}}>Hemos descubierto que eres...</p>
                                            </Button>
                                        </div>
                                    </div>
                                </Grow>
                            </div>
            }
        </div>
    )
}

export default PageOne;