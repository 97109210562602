import { Outlet } from "react-router-dom"

const Index: React.FC = () => {
    return(
        <>
            <Outlet></Outlet>
        </>
    )
}

export default Index;