import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Slide, Grow, Button } from "@mui/material";
import "../Styles/Pages.scss";
import { useContext } from "react";
import ContextP from "../Context/Context";
import emojiParty from "../Media/Images/partyFace.svg"

const PageTwo: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const context = useContext(ContextP);
  const [nameUserCategory, setNameUserCategory] = useState('')
  const titles = [
    {
      category: 'vodka',
      name: 'vodka drinker',
    },
    {
      category: 'ginebra',
      name: 'gin devoto'
    },
    {
      category: 'ron',
      name: 'ronero intrépido'
    },
    {
      category: 'tequila',
      name: 'amigo tequilero'
    },
    {
      category: 'whisky',
      name: 'whiskey enthusiast'
    },
    {
      category: 'mezcales',
      name: 'amigo mezcalero'
    },
    {
      category: 'licores',
      name: 'degustador de elixir'
    },
    {
      category: 'aperitivos',
      name: 'catador de aperitivos'
    },
    {
      category: 'digestivos',
      name: 'digestive taster'
    },
    {
      category: 'aperitivos / digestivos',
      name: 'degustador mixologo'
    },
    {
      category: 'mixology liqueurs',
      name: 'degustador mixologo'
    },{
      category: 'aguardiente',
      name: 'gourmet espirituoso'
    },
    {
      category: 'ready to drink',
      name: 'amigo mixero'
    },
  ]

  const categoriesBase = [
    {
      category: 'beers',
      name: 'beer lovers'
    },
    {
      category: 'non-alcoholic',
      name: 'Drinkero Refrescante'
    },
    {
      category: 'wines',
      name: 'Sommelier Sofisticado'
    }
  ];

  

  useEffect(()=>{
    console.log(context.data);
    if(Object.keys(context.data.infoCategory).length !== 0 ){
      if(context.data.infoCategory.category === 'liquors'){
        const finded = titles.find((element:any) => element.category === context.data.infoCategory.name);
        if(finded){
          setNameUserCategory(finded.name)
        }else{
          setNameUserCategory('BARLLENO LOVER')
        }
      }else{
        const finded = categoriesBase.find((element:any) => element.category.toLowerCase() === context.data.infoCategory.category);
        if(finded !== undefined){
          setNameUserCategory(finded.name)
        }else{
          setNameUserCategory('BARLLENO LOVER')
        }
      }
    }else{
      setNameUserCategory('AMIGO DRINKERO')
    }

  },[])


  // Navegacion a la siguiente pagina
  const navigateTo = (): void => {
    if(Object.keys(context.data.infoCategory).length === 0){
      navigate(`/${id}/orders/fourth`);
    }else{
      navigate(`/${id}/orders/three`);
    }

  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className="page-two">
        <h1 hidden={true}>{context.data.information.title}</h1>
        <div style={{ height: "100%", position: "relative" }}>
          <Grow in={true}>
            <div
              style={{ display: "flex", alignItems: "center", height: "100%" }}>
              <div className="messagePageOne">
                <div style={{position: 'absolute', top: -50, right: '50%', marginRight: -50}}><img style={{width: '100px', maxWidth: 200}} src={emojiParty}></img></div>

                <div style={{fontWeight: 'bold', fontSize: 'large', marginTop: 45, width: '50%', marginInline: 'auto'}}>
                  Uno de nuestros 
                </div>

                <div className="categoryContainer">
                  {nameUserCategory.toUpperCase()}                  
                </div>
                
                <Button
                  sx={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    background: "#e84137",
                    color: "white",
                    borderRadius: 20,
                    paddingInline: 2,
                  }}
                  aria-label="delete"
                  onClick={navigateTo}>
                  ¿QUÉ MÁS? {">>>"}
                </Button>
              </div>
            </div>
          </Grow>
        </div>
      </div>
    </Slide>
  );
};

export default PageTwo;
