import React, { Dispatch, SetStateAction } from 'react';
import {
    Slide,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { TransitionProps } from '@mui/material/transitions';
import "../Styles/Pages.scss"
import CloseIcon from '@mui/icons-material/Close';
import logo from "../Media/Images/lupe-reyes.png"
import crown from "../Media/Images/crown.png"

import circle1 from "../Media/Images/circle1.png"
import circle2 from "../Media/Images/circle2.png"

import square1 from "../Media/Images/square1.svg"
import square2 from "../Media/Images/square2.svg"
import square3 from "../Media/Images/square3.svg"
import square4 from "../Media/Images/square4.png"


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsInterface {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const Instructions: React.FC<PropsInterface> = (props: PropsInterface) => {
    const close = () => {
        props.setOpen(false)
    }
    return (
        <Dialog fullWidth open={props.open} TransitionComponent={Transition} onClose={close} className="modal">
            <DialogTitle style={{backgroundColor: '#B3261E', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between',fontWeight: 'bold'}}>
                <div style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                    <img style={{width: 60, height: 30, marginLeft: 0}} src={logo} alt='logolo'></img>
                    <p style={{margin: 0, fontSize: 'medium'}}>INSTRUCCIONES</p>
                </div>
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{backgroundColor: '#E77E2C', color: 'white', width: 35, height: 35}}
                >
                    <CloseIcon fontSize='small' />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{backgroundColor: "#EAEAEA", color: '#112A56'}}>
                <div style={{display: 'flex', gap: 7}}>
                    <div>
                        <p><img style={{height: 15}} src={crown} alt="coronita"></img></p>
                    </div>
                    <div>
                        <p>Cuando compres un producto dentro de la categoría Lupe Reyes  llenarás una casilla</p>
                        <div style={{display: 'flex', alignItems: 'center', gap: '5%'}}>
                            <img style={{height: 35, margin: 0}}  src={circle1}></img>
                            <ArrowForwardIcon sx={{color: '#e84137'}}></ArrowForwardIcon>
                            <img style={{height: 35, margin: 0}} src={circle2}></img>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 7}}>
                    <div>
                        <p><img style={{height: 15}} src={crown} alt="coronita"></img></p>
                    </div>
                    <div>
                        <p>Puedes llenar tu Lupe Reyes de manera horizontal o vertical, escogiendo filas o columnas o de forma diagonal</p>
                        
                    </div>
                    
                </div>
                <div style={{display: 'flex', gap:'5%', justifyContent: 'center'}}>
                    <div>
                        <img style={{maxHeight: 100}} src={square1}></img>
                    </div>
                    <div>
                        <img style={{maxHeight: 100}} src={square2}></img>
                    </div>
                    <div>
                        <img style={{maxHeight: 100}} src={square3}></img>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 7}}>
                    <div>
                        <p><img style={{height: 15}} src={crown} alt="coronita"></img></p>
                    </div>
                    <div>
                        <p>Las casillas con gorro indican una pausa para que puedas comprar lo que gustes</p>
                        <div style={{display: 'flex'}}>
                            <img style={{width: 50, height:50, margin: 0}} src={square4}></img>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 7}}>
                    <div>
                        <p><img style={{height: 15}} src={crown} alt="coronita"></img></p>
                    </div>
                    <div>
                        <p>Obtendrás tu premio al completar tu Lupe Reyes en la fecha establecida</p>
                    </div>
                </div>
                <div>
                    <p style={{color: '#e84137', fontWeight: 'bold', fontSize: 'smaller', textAlign: 'center'}}>¡Compra todos los días y ganarás más premios!</p>
                </div>
                <div style={{textAlign: 'center', paddingTop: 15, borderTop: '1px solid #D9D9D9'}}>
                    <p style={{color: '#3C3F43', fontWeight: 'bold', fontSize: 'x-small'}}>Restricciones aplican. Promoción válida solo en productos seleccionados hasta agotar existencias, fechas de promoción del 12 de diciembre de 2023 al 6 de enero de 2024. </p>
                    <p style={{fontSize: 'small'}}>Para más información visita nuestros</p>
                    <a style={{color: '#e84137', fontSize: 'small', fontWeight: 'bold', textDecoration: 'underline'}}>Términos y condiciones</a>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default Instructions; 