import { useNavigate, useParams } from "react-router-dom";
import { Slide, Grow, Button } from "@mui/material";
import "../Styles/Pages.scss";
import { useContext, useState, useEffect } from "react";
import ContextP from "../Context/Context";
import starPage from "../Media/Images/heartFire.svg";

const PageThree: React.FC = () => {
  const { id } = useParams();
  const context = useContext(ContextP);
  const navigate = useNavigate();
  const [information, setInformation] = useState({
    title: "",
    image: "",
    message: "",
    products: [],
  });
  const [products, setProducts] = useState([])

  const navigateTo = (): void => {
    navigate(`/${id}/orders/fourth`);
  };

  useEffect(() => {
    setInformation(context.data.information);
    setProducts(context.data.products)
    console.log(context.data.infoProduct)
  }, []);

  // Navegacion a la siguiente pagina

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className="page-three">

          <div style={{ height: "100%", position: "relative" }}>
            <div style={{ position: "absolute", left: "60%", top: "5%" }}>
              <img src={starPage} alt="hourglass"></img>
            </div>
            <div style={{ position: "absolute", left: "20%", bottom: "5%" }}>
              <img src={starPage} alt="hourglass"></img>
            </div>
            <Grow in={true}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}>
                <div className="messagePageOne">
                  <div
                    style={{
                      position: "absolute",
                      right: "-20px",
                      top: "-20px",
                    }}>
                    <img src={starPage} alt="hourglass"></img>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "-20px",
                      bottom: "-30px",
                    }}>
                    <img src={starPage} alt="hourglass"></img>
                  </div>

                  <div style={{ fontWeight: "bold", marginBottom: 175 }}>
                    Y tus favoritos sobre todos fueron
                  </div>
      
                  <div style={{width: '100%', height: 200, top: 'calc(50% - 100px)', left: 0, position: 'fixed'}}>
                            <div style={{overflow: 'auto', display: 'flex', gap: 20}}>
                               <div className="invisibleProduct">
                                <div style={{borderRadius: 15, height: 100}}>
                    
                                </div>
                              </div>
                              {products.map((item: any, index: number) => {return(
                                index < 5 && (
                                  <div key={index} className="product">
                                  <div style={{border: '1px solid #EAEAEA', borderRadius: 15, height: 100, width: 120}}>
                                    <img style={{objectFit: 'contain' ,width: '100%', height: '100%'}} src={item.image ? item.image : "https://firebasestorage.googleapis.com/v0/b/barlleno.appspot.com/o/public%2FiconBotella2021.png?alt=media&token=3f057adb-1ae2-4fdc-b3ff-a9ef6ab94dd1"}></img>
                                  </div>
                                  <div style={{fontWeight: 'bold', marginTop: 10, textAlign: 'left'}}>
                                    {item.name}
                                  </div>
                                </div>
                                )
                              )})}
                            
                              <div className="invisibleProduct">
                                <div style={{borderRadius: 15, height: 100}}>
                    
                                </div>
                              </div>
                            </div>                
                  </div>

                  <Button
                    sx={{
                      marginTop: "80px",
                      fontWeight: "bold",
                      background: "#e84137",
                      color: "white",
                      borderRadius: 20,
                      paddingInline: 2,
                    }}
                    aria-label="delete"
                    onClick={navigateTo}>
                    CONTINUE :D
                  </Button>
                </div>
              </div>
            </Grow>
          </div>
        
      </div>
    </Slide>
  );
};

export default PageThree;
