import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageOne from './Pages/PageOne';
import PageTwo from './Pages/PageTwo';
import PageThree from './Pages/PageThree';
// import PageFourth from './Pages/PageFourth';
import NotFound from './Pages/NotFound';
import Index from './Pages/Index';
import Summary from './Pages/Summary';
import PageFourth from './Pages/PageFourth';
import PageFifth from './Pages/PageFifth';

const App: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path=':id' element={ <Index /> } >
					<Route path='orders' element={ <Index/>}>
						<Route path='' element={ <PageOne /> }/>
						<Route path='two' element={ <PageTwo /> }/>
						<Route path='three' element={ <PageThree /> }/>
						<Route path='fourth' element={ <PageFourth /> }/>
						<Route path='fifth' element={ <PageFifth /> }/>
					</Route>
					<Route path='summary' element={ <Index/>}>
						<Route path='' element={ <Summary />}/>
					</Route>
				</Route>
				
				{/* 				
				<Route path='/:id/orders' element={ <PageOne /> }/>
				<Route path='/:id/orders/two' element={ <PageTwo /> }/>
				<Route path='/:id/orders/three' element={ <PageThree /> }/>
				<Route path='/:id/orders/fourth' element={ <PageFourth /> }/> */}
				<Route path='*' element={ <NotFound /> }/>
				
				{/* <Route path='' element={ <Navigate to="/eJiN5MOvoEa4cR3Ny4prFp7czpw2/orders" /> } /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
