import { Slide, Grow, Button } from "@mui/material";
import "../Styles/Pages.scss";
import starPage from "../Media/Images/heartFire.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import ContextP from "../Context/Context";


const PageFourth: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const navigateTo = (): void => {
        navigate(`/${id}/orders/fifth`);
    };
    const context = useContext(ContextP);
    const [nameUserCategory, setNameUserCategory] = useState('general')

    const titles = [
      {
        category: 'vodka',
        image: 'vodka',
      },
      {
        category: 'ginebra',
        image: 'gin'
      },
      {
        category: 'ron',
        image: 'ron'
      },
      {
        category: 'tequila',
        image: 'tequila'
      },
      {
        category: 'whisky',
        image: 'wiskey'
      },
      {
        category: 'mezcales',
        image: 'mezcal'
      },
      {
        category: 'licores',
        image: 'licores'
      },
      {
        category: 'aperitivos',
        image: 'aperitivos'
      },
      {
        category: 'digestivos',
        image: 'digestivos'
      },
      {
        category: 'aperitivos / digestivos',
        image: 'licores'
      },
      {
        category: 'mixology liqueurs',
        image: 'mixologia'
      },{
        category: 'aguardiente',
        image: 'vodka'
      },
      {
        category: 'ready to drink',
        image: 'rtd'
      },
    ]
  
    const categoriesBase = [
      {
        category: 'beers',
        image: 'beer'
      },
      {
        category: 'non-alcoholic',
        image: 'nonAlcoholic'
      },
      {
        category: 'wines',
        image: 'wine'
      }
    ];

    useEffect(()=>{
      console.log(context.data);
      if(Object.keys(context.data.infoCategory).length !== 0 ){
        if(context.data.infoCategory.category === 'liquors'){
          const finded:any = titles.find((element:any) => element.category === context.data.infoCategory.name);
          if(finded){
            setNameUserCategory(finded.image)
          }else{
            setNameUserCategory('general')
          }
        }else{
          const finded:any = categoriesBase.find((element:any) => element.category.toLowerCase() === context.data.infoCategory.category);
          if(finded !== undefined){
            setNameUserCategory(finded.image)
          }else{
            setNameUserCategory('general')
          }
        }
      }else{
        setNameUserCategory('general')
      }
  
    },[])

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className="page-fourth">
        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", left: "60%", top: "5%" }}>
            <img src={starPage} alt="hourglass"></img>
          </div>
          <div style={{ position: "absolute", left: "20%", bottom: "5%" }}>
            <img src={starPage} alt="hourglass"></img>
          </div>
          <Grow in={true}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}>
              <div className="messagePageOne">
                <div
                  style={{
                    position: "absolute",
                    right: "-20px",
                    top: "-20px",
                  }}>
                  <img src={starPage} alt="hourglass"></img>
                </div>
                <div
                  style={{
                    position: "absolute",
                    left: "-20px",
                    bottom: "-30px",
                  }}>
                  <img src={starPage} alt="hourglass"></img>
                </div>
                <div style={{fontSize: 'larger', fontWeight: 'bold'}}>
                    Por un 2024 lleno de alegrías
                </div>
                <div className="categoryContainer">
                    <img style={{height: '100%', width: '100%', maxWidth: 200}} src={require('../Media/Images/category/'+nameUserCategory+'.png')}></img>
                </div>
                <Button
                    sx={{
                      marginTop: "10px",
                      fontWeight: "bold",
                      background: "#112A56",
                      color: "white",
                      borderRadius: 20,
                      paddingInline: 2,
                    }}
                    aria-label="delete"
                    onClick={navigateTo}>
                    ¡¡¡SALUD!!!
                  </Button>
              </div>
            </div>
          </Grow>
        </div>
      </div>
    </Slide>
  );
};
export default PageFourth;
